import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";
import FormTextEditor from "components/FormField/TextEditor";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import {
  useCreateTopic,
  useGetBoards,
  useGetChapters,
  useGetClasses,
  useGetSubjects,
  useGetTopic,
  useUpdateTopic,
  useGetTopics,
} from "hooks/api/subject";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateTopic = () => {
  const { id } = useParams();
  const history = useHistory();
  const [rows, setRows] = useState([]);

  const [valueAdded, setValueAdded] = React.useState(false);

  const { data: topic } = useGetTopic(id);
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: chapters } = useGetChapters();
  const { data: topics } = useGetTopics();

  const createTopic = useCreateTopic();
  const updateTopic = useUpdateTopic();

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm();

  async function onSubmit(data) {
    try {
      const body = { ...data };

      id
        ? await updateTopic.mutateAsync({ id, body })
        : await createTopic.mutateAsync(body);
    } catch (err) {}
  }

  const watchBoard = watch("boardId");
  const watchClass = watch("classId");
  const watchSubject = watch("subjectId");

  useEffect(() => {
    valueAdded && setValue("chapterId", "");
  }, [chapters, watchBoard, watchClass, watchSubject]);

  useEffect(() => {
    if (id && !valueAdded && Object.values(watch).length > 0) {
      setValueAdded(true);
    }
  }, [watch]);

  const chaptersOptions = useMemo(() => {
    let temp = [];
    if (watchBoard && watchClass && watchSubject) {
      for (let i = 0; i < chapters?.data?.length; i++) {
        if (
          chapters?.data[i].board_id == watchBoard &&
          chapters?.data[i].class_id == watchClass &&
          chapters?.data[i].subject_id == watchSubject
        ) {
          temp.push({
            label: chapters?.data[i].name,
            value: chapters?.data[i].id,
          });
        }
      }
    }
    return temp;
  }, [chapters, watchBoard, watchClass, watchSubject]);

  React.useEffect(() => {
    if (topic?.data?.[0]) {
      let values = _.pick(topic?.data?.[0], [
        "board_id",
        "class_id",
        "subject_id",
        "chapter_id",
        "name",
        "notes",
      ]);

      values = {
        boardId: values.board_id,
        classId: values.class_id,
        subjectId: values.subject_id,
        chapterId: values.chapter_id,
        name: values.name,
        notes: values.notes,
      };

      Object.entries(values).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
    }
  }, [topic]);

  useEffect(() => {
    if (topics?.data) {
      setRows(topics.data);
    }
  }, [topics]);

  const getCurrentTopicIndex = () => {
    return rows.findIndex((topic) => topic.id == id);
  };

  const navigateToTopic = (newIndex) => {
    if (newIndex >= 0 && newIndex < rows.length) {
      const newTopicId = rows[newIndex].id;
      history.push(`/topics/edit/${newTopicId}`);
    }
  };

  const onNext = () => {
    const currentIndex = getCurrentTopicIndex();
    if (currentIndex !== -1 && currentIndex < rows.length - 1) {
      navigateToTopic(currentIndex + 1);
    }
  };

  const onPrevious = () => {
    const currentIndex = getCurrentTopicIndex();
    if (currentIndex !== -1 && currentIndex > 0) {
      navigateToTopic(currentIndex - 1);
    }
  };

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Chapter"
        Breadcrumb={[
          { name: "Topics", navigate: "/topics" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} Topic</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.boardId}
                item={{
                  name: "boardId",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.classId}
                item={{
                  name: "classId",
                  label: "Class",
                  options: classes?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.subjectId}
                item={{
                  name: "subjectId",
                  label: "Subject",
                  options: subjects?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.chapterId}
                item={{
                  name: "chapterId",
                  label: "Chapter",
                  options: chaptersOptions,
                  rules,
                }}
              />
              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  name: "name",
                  label: "Topic Name",
                  placeholder: "Enter Topic Name",
                  rules,
                }}
              />
            </div>
            <div className="row clearfix">
              <div className="col-12 mb-4">
                <FormTextEditor
                  control={control}
                  error={formState?.errors?.notes}
                  item={{
                    name: "notes",
                    label: "Topic Detail",
                    placeholder: "Write",
                    required: true,
                  }}
                />
              </div>
            </div>

            <div className="d-flex align-items-center">
              {id && (
                <>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={onPrevious}
                    disabled={getCurrentTopicIndex() <= 0}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="btn btn-success mx-3"
                    onClick={onNext}
                    disabled={getCurrentTopicIndex() >= rows.length - 1}
                  >
                    Next
                  </button>
                </>
              )}
              <button
                type="submit"
                disabled={formState.isSubmitting}
                className="btn btn-primary ml-auto"
              >
                {id ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTopic;
