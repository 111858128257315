import React from "react";

const infoStyle = { 
  border: "1px solid #c5c5c5", 
  padding: "12px",
  minHeight: "45px",
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  '@media print': {
    border: "1px solid #000",
    padding: "6px",
    minHeight: "40px",
  }
};

const labelStyle = {
  fontWeight: "500",
  fontSize: "16px",
  '@media print': {
    fontWeight: "600",
    fontSize: "15px",
  }
};

// Specific style for Roll No label
const rollNoLabelStyle = {
  ...labelStyle,
  fontSize: "15px", // Smaller font size for Roll No
  '@media print': {
    ...labelStyle['@media print'],
    fontSize: "13px",
  }
};

const PaperStudentEntry = ({ data }) => {
  return (
    <div 
      className="row clearfix" 
      style={{ 
        margin: "0 0px 25px 0px",
        pageBreakInside: "avoid",
        '@media print': {
          margin: "0 0px 15px 0px"
        }
      }}
    >
      {/* First Row */}
      <div className="col-2" style={infoStyle}>
        <span style={labelStyle}>Name:</span>
      </div>
      <div className="col-6" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        <span style={labelStyle}>Grade:</span>
      </div>
      <div className="col-1" style={infoStyle}>
        {data?.paperMcqs[0]?.classname}
      </div>
      <div className="col-1" style={infoStyle}>
        <span style={labelStyle}>Section:</span>
      </div>
      <div className="col-1" style={infoStyle}></div>

      {/* Second Row */}
      <div className="col-2" style={infoStyle}>
        <span style={labelStyle}>Subject:</span>
      </div>
      <div className="col-6" style={infoStyle}>
        {data?.paperMcqs[0]?.subject_name}
      </div>
      <div className="col-1" style={infoStyle}>
        <span style={labelStyle}>Date:</span>
      </div>
      <div className="col-1" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        <span style={rollNoLabelStyle}>Roll No:</span>
      </div>
      <div className="col-1" style={infoStyle}></div>
    </div>
  );
};

export default PaperStudentEntry;
