import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// util
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";
import { intToRoman, numberToAlphabet } from "utils/Helper";

const alphabets = ["a", "b", "c", "d", "e", "f", "g", "h"];



export const ShortQuestions = ({
  is10,
  data,
  subject_id,
  isMcqExist,
  subjectCheck,
}) => {
  console.log("data!!!", data);
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = 1;

  // Helper function to convert number to Roman numeral
  const numberToRoman = (num) => {
    const romanNumerals = [
      { value: 10, numeral: "X" },
      { value: 9, numeral: "IX" },
      { value: 5, numeral: "V" },
      { value: 4, numeral: "IV" },
      { value: 1, numeral: "I" },
    ];

    let result = "";
    let number = num;

    for (let i = 0; i < romanNumerals.length; i++) {
      while (number >= romanNumerals[i].value) {
        result += romanNumerals[i].numeral;
        number -= romanNumerals[i].value;
      }
    }

    return result;
  };

  return (
    <div>
      {/* Question Header */}
      {subjectCheck === 12 ? (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "1px solid black",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "40px",
                  padding: "10px",
                  border: "1px solid black",
                  textAlign: "left",
                }}
              >
                Q.2
              </th>
              <th
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  width: "35%",
                  textAlign: "left",
                }}
              >
                Question
              </th>
              <th
                style={{
                  width: "40px",
                  padding: "10px",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Marks
              </th>
              <th
                style={{
                  width: "40px",
                  padding: "10px",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              ></th>
              <th
                style={{
                  padding: "10px",
                  border: "1px solid black",
                  width: "35%",
                  textAlign: "left",
                }}
              >
                Question
              </th>
              <th
                style={{
                  width: "40px",
                  padding: "10px",
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Marks
              </th>
            </tr>
          </thead>
        </table>
      ) : (
        <div
          style={{
            border: subjectCheck === 13 ? "none" : "1px solid black",
            borderBottom: "none",
            padding: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontWeight: "bold" }}>
              Q. 2{" "}
              <span style={{ marginLeft: "24px" }}>
                Attempt the following questions
              </span>
            </div>
            <div style={{ fontWeight: "bold" }}>(11x3 = 33)</div>
          </div>
        </div>
      )}

      {/* Questions Table */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid black",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          {data?.paperShortQuestions?.map((ele, index) => {
            const hasMultipleParts = ele?.sq_question_ids?.length > 1;

            return (
              <tr key={index}>
                {/* Question Number Column */}
                <td
                  style={{
                    width: "40px",
                    padding: "10px",
                    border: "1px solid black",
                    verticalAlign: "top",
                    fontWeight: "bold",
                  }}
                >
                  {convertToRoman(index + startingQuestionNumber)}
                </td>

                {/* First Question Column */}
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: hasMultipleParts ? "35%" : "80%",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                    verticalAlign: "top",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {console.log('Current element:', ele)}
                    {console.log('File path:', ele?.sq_question_ids?.[0]?.file)}
                    {console.log('Complete image URL:', `${IMAGE_URL}/${ele?.sq_question_ids?.[0]?.file}`)}

                    {ele?.sq_question_ids?.[0]?.question &&
                      parse(`${ele?.sq_question_ids[0].question}`)}
                    
                    {ele?.sq_question_ids?.[0]?.file && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0",
                          maxWidth: "100%",
                        }}
                      >
                        <img
                          src={`${IMAGE_URL}/${ele?.sq_question_ids[0].file}`}
                          alt="Question"
                          onError={(e) => {
                            console.error('Image failed to load:', e);
                            console.log('Failed image URL:', e.target.src);
                          }}
                          style={{
                            maxWidth: "250px",
                            height: "auto",
                            objectFit: "contain",
                            display: "block",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </td>

                {/* First Question Marks */}
                <td
                  style={{
                    width: "40px",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  {ele?.marks}
                </td>

                {/* OR Column */}
                <td
                  style={{
                    width: "40px",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    fontWeight: "bold",
                    verticalAlign: "middle",
                  }}
                >
                  OR
                </td>

                {/* Second Question Column */}
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: "35%",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                    verticalAlign: "top",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {ele?.sq_question_ids?.[1]?.question &&
                      parse(`${ele?.sq_question_ids[1].question}`)}
                    {ele?.sq_question_ids?.[1]?.file && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0",
                          maxWidth: "100%",
                        }}
                      >
                        <img
                          src={`${IMAGE_URL}/${ele?.sq_question_ids[1].file}`}
                          alt="Question"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                            display: "block",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </td>

                {/* Second Question Marks */}
                <td
                  style={{
                    width: "40px",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  {ele?.marks}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const EnglishShortQuestions = ({
  is10,
  data,
  subject_id,
  isMcqExist,
  subjectCheck,
}) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = 2;

  const getQuestionNumber = (index) => {
    const currentNumber = index + startingQuestionNumber;

    // Special handling for Q3 and its parts
    if (currentNumber === 2) return "Q.2";
    if (currentNumber === 3) return "Q.3a";
    if (currentNumber === 4) return "Q.3b";
    if (currentNumber === 5) return "Q.4a";
    if (currentNumber === 6) return "b";
    if (currentNumber === 7) return "c";

    // Adjust numbers after 3b to continue from 4
    if (currentNumber > 4) {
      return `Q.${currentNumber - 1}`;
    }

    return `Q.${currentNumber}`;
  };

  const shouldShowOR = (index, questionNumber) => {
    const currentNumber = index + startingQuestionNumber;
    // Don't show OR for Q.3a (when currentNumber is 3)
    return currentNumber !== 3;
  };

  return (
    <div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div className="mb-3">
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>
                  {subjectCheck === 11 && <>{getQuestionNumber(index)}.</>}
                </span>
                {<span className="font-weight-bold">{ele.text}</span>}
                <div className="font-weight-light">
                <span className="font-weight-bold">Note:</span>	Question (i) about summary writing is compulsory carrying 06 marks while the rest of the questions carry 03 marks each.	
                  </div>
              </div>
              <div className="col-2 text-right">
                {subjectCheck === 11 && ele.marks}
              </div>
            </div>

            {ele.ui_style == "q_two_part_a" && (
              <>
                <div className="row">
                  {/* {ele?.note && (
                    <div className="col-12 mb-4">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "15px",
                          }}
                        >
                          <span style={{ width: "100%" }}>
                            {parse(`${ele.note}`)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {ele?.sq_question_ids?.map((ques, qIndex) => (
                    <div
                      className="col-12"
                      key={qIndex}
                      style={{
                        marginBottom:
                          qIndex === 1 || qIndex === 3 ? "0" : "15px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "15px",
                          }}
                        >
                          {qIndex + 1 !== ele?.sq_question_ids?.length && (
                            <span
                              style={{ minWidth: "25px", fontWeight: "bold" }}
                            >
                              {`${intToRoman(qIndex + 1)}.`}
                            </span>
                          )}
                          <span style={{ width: "100%" }}>
                            {parse(`${ques.question}`)}
                          </span>
                        </div>

                        {ques.parts?.map((part, partIndex) => (
                          <div
                            key={partIndex}
                            style={{
                              marginLeft: "25px",
                              marginBottom: partIndex === 0 ? "10px" : "0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <span
                                style={{
                                  minWidth: "35px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${convertToRoman(partIndex + 1)}.`}
                              </span>
                              <span style={{ width: "100%" }}>
                                {parse(`${part.question}`)}
                              </span>
                            </div>
                          </div>
                        ))}

                        {/* {(qIndex === 1 || qIndex === 2 || qIndex === 4) && (
                          <div className="text-center font-weight-bold my-2">
                            OR
                          </div>
                        )} */}
                      </div>

                      {/* {shouldShowOR(index) &&
                        qIndex + 1 < ele?.sq_question_ids?.length && (
                          <div className="text-center font-weight-bold my-2">
                            OR
                          </div>
                        )} */}
                    </div>
                  ))}
                </div>
                {/* 
                {ele?.note?.includes("answer the questions") && (
                  <div className="mt-3">
                    {ele?.sq_question_ids?.map((ques, qIndex) => (
                      <div className="col-12 mb-2">
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          {qIndex + 1 !== ele?.sq_question_ids?.length && ( // Skip numbering for the last question
                            <span
                              style={{ minWidth: "25px", fontWeight: "bold" }}
                            >
                              {`${qIndex + 1}.`}
                            </span>
                          )}
                          <span style={{ width: "100%" }}>
                            {parse(`${ques.question}`)}
                          </span>
                          {ques.marks && (
                            <span className="ml-2">({ques.marks})</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )} */}
              </>
            )}

            {ele.ui_style == "q_three_part_a" && (
              <>
                <div className="row">
                  {ele?.sq_question_ids?.map((ques, qIndex) => (
                    <div className="col-12 mb-4" key={qIndex}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "15px",
                          }}
                        >
                          <span
                            style={{ minWidth: "25px", fontWeight: "bold" }}
                          >
                            {`${intToRoman(qIndex + 1)}.`}
                          </span>
                          <span style={{ width: "100%" }}>
                            {parse(`${ques.question}`)}
                          </span>
                        </div>

                        {ques.parts?.map((part, partIndex) => (
                          <div
                            key={partIndex}
                            style={{
                              marginLeft: "25px",
                              marginBottom: partIndex === 0 ? "10px" : "0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <span
                                style={{
                                  minWidth: "35px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${convertToRoman(partIndex + 1)}.`}
                              </span>
                              <span style={{ width: "100%" }}>
                                {parse(`${part.question}`)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Only show OR if it's not Q.3a and there are more questions */}
                      {shouldShowOR(index) &&
                        qIndex + 1 < ele?.sq_question_ids?.length && (
                          <div className="text-center font-weight-bold my-2">
                            OR
                          </div>
                        )}
                    </div>
                  ))}
                </div>

                {ele?.note?.includes("answer the questions") && (
                  <div className="mt-3">
                    {ele?.sq_question_ids?.map((ques, qIndex) => (
                      <div className="col-12 mb-2">
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <span
                            style={{ minWidth: "25px", fontWeight: "bold" }}
                          >
                            {`${qIndex + 1}.`}
                          </span>
                          <span style={{ width: "100%" }}>
                            {parse(`${ques.question}`)}
                          </span>
                          {ques.marks && (
                            <span className="ml-2">({ques.marks})</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            {ele.ui_style == "q_four_part_a" && (
              <>
                <div className="row">
                  {ele?.sq_question_ids?.map((ques, qIndex) => (
                    <div className="col-12 mb-4" key={qIndex}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* Question header (a/b/c) */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginBottom: "15px",
                          }}
                        >
                          <span
                            style={{ minWidth: "25px", fontWeight: "bold" }}
                          >
                            {qIndex > 0 ? `${intToRoman(qIndex + 1)}` : ""}
                          </span>
                          <span style={{ width: "100%" }}>
                            {parse(`${ques.question}`)}
                          </span>
                        </div>

                        {/* Parts a, b, c */}
                        {ques.parts?.map((part, partIndex) => (
                          <div
                            key={partIndex}
                            style={{
                              marginLeft: "25px",
                              marginBottom: partIndex === 0 ? "10px" : "0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <span
                                style={{
                                  minWidth: "35px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${String.fromCharCode(97 + partIndex)}.`}
                              </span>
                              <span style={{ width: "100%" }}>
                                {parse(`${part.question}`)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                {ele?.note?.includes("answer the questions") && (
                  <div className="mt-3">
                    {ele?.sq_question_ids?.map((ques, qIndex) => (
                      <div className="col-12 mb-2">
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <span
                            style={{ minWidth: "25px", fontWeight: "bold" }}
                          >
                            {`${qIndex + 1}.`}
                          </span>
                          <span style={{ width: "100%" }}>
                            {parse(`${ques.question}`)}
                          </span>
                          {ques.marks && (
                            <span className="ml-2">({ques.marks})</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const LongQuestions = ({ data, startIndex, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-10">
              <span>Q.{index + startIndex + startingQuestionNumber} </span>{" "}
              {ele.text}
            </div>
            <div className="col-2 text-right">{ele.marks}</div>
          </div>

          {ele.ui_style == "math_long_q9_include_or_and_single_marks" &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${
                    alphabets[QueIndex + 0]
                  })`}</span>
                  <span style={{ width: "100%" }}>
                    <MathJax math={`${ques.question}`} />
                  </span>

                  {QueIndex == 0 && (
                    <div
                      style={{ maxWidth: "100%" }}
                      className="text-right font-weight-bold"
                    >
                      (0{ques.marks})
                    </div>
                  )}
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="aaaa"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}

                {ele?.question_ids.length > 1 && QueIndex == 0 && (
                  <div
                    className="text-center font-weight-bold mt-1 mb-1"
                    style={{ width: "100%" }}
                  >
                    OR
                  </div>
                )}
              </>
            ))}

          {!ele.ui_style &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${
                    alphabets[QueIndex + 0]
                  })`}</span>
                  <span style={{ width: "100%" }}>
                    {isMath ? (
                      <MathJax math={`${ques.question}`} />
                    ) : (
                      parse(`${ques.question}`)
                    )}
                  </span>

                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    (0{ques.marks})
                  </div>
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="bbb"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}
              </>
            ))}
        </div>
      ))}
    </div>
  );
};

export const EnglishLongQuestions = ({
  data,
  startIndex,
  subject_id,
  isMcqExist,
}) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  console.log("data in english long", data);
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-4" key={index}>
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-12">
              <span className="font-weight-bold">
                Q.{index + startIndex + startingQuestionNumber}{" "}
              </span>{" "}
              {ele.text}
            </div>
          </div>

          {ele.ui_style === "math_long_q9_include_or_and_single_marks" &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <div key={QueIndex}>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span
                    style={{ marginRight: "5px", fontWeight: "bold" }}
                  >{`(${String.fromCharCode(97 + QueIndex)})`}</span>
                  <span style={{ width: "100%" }}>{`${ques.question}`}</span>

                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    (0{ques.marks})
                  </div>
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="Question Image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}

                {/* Add "OR" between the first and second sub-questions */}
                {ele?.question_ids.length === 2 && QueIndex === 0 && (
                  <div
                    className="text-center font-weight-bold mt-1 mb-1"
                    style={{ width: "100%" }}
                  >
                    OR
                  </div>
                )}
              </div>
            ))}

          {!ele.ui_style &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <div key={QueIndex}>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span
                    style={{ marginRight: "5px", fontWeight: "bold" }}
                  >{`(${String.fromCharCode(97 + QueIndex)})`}</span>
                  <span style={{ width: "100%" }}>
                    {isMath ? (
                      <MathJax math={`${ques.question}`} />
                    ) : (
                      parse(`${ques.question}`)
                    )}
                  </span>

                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    (0{ques.marks})
                  </div>
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="Question Image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}

                {/* Add "OR" between the first and second sub-questions */}
                {ele?.question_ids.length === 2 && QueIndex === 0 && (
                  <div
                    className="text-center font-weight-bold mt-1 mb-1"
                    style={{ width: "100%" }}
                  >
                    OR
                  </div>
                )}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export const EnglishFedralLongQuestions = ({
  data,
  startIndex,
  subject_id,
  isMcqExist,
}) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  const getQuestionNumber = (index) => {
    const currentNumber = index + startIndex + startingQuestionNumber;

    // Special handling for Q3 and its parts
    if (currentNumber === 8) return "5";
    if (currentNumber === 9) return "6";
    if (currentNumber === 10) return "7";

    return currentNumber;
  };

  const getQuestionMarks = (questionNumber) => {
    switch (questionNumber) {
      case "5":
        return "(8)";
      case "6":
        return "(6)";
      case "7":
        return "(6)";
      default:
        return "";
    }
  };

  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-4" key={index}>
          {/* Display the note if it exists */}
          {ele.note && (
            <p
              className="font-weight-bold mb-2"
              style={{ marginBottom: "1rem" }}
            >
              {ele.note}
            </p>
          )}

          {ele?.question_ids?.map((ques, QueIndex) => (
            <div key={QueIndex}>
              <p
                className="col-12"
                style={{
                  display: "flex",
                  alignItems: isMath ? "center" : "flex-start",
                  margin: 0,
                }}
              >
                {/* Render Q. number only for the first question */}
                {QueIndex === 0 && (
                  <span
                    className="row clearfix font-weight-bold mb-2"
                    style={{ marginRight: "16px" }}
                  >
                    Q.{getQuestionNumber(index)}
                  </span>
                )}
                <span style={{ width: "100%", fontWeight: "bold" }}>
                  {parse(`${ques.question}`)}
                </span>

                {/* Show hardcoded marks for specific questions */}
                {QueIndex === 0 && (
                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    {getQuestionMarks(getQuestionNumber(index))}
                  </div>
                )}
              </p>

              {ques.file && (
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={ques.file}
                    alt="Question Image"
                    style={{ maxWidth: "500px" }}
                  />
                </p>
              )}

              {/* Add "OR" between the first and second sub-questions */}
              {ele?.question_ids.length === 2 && QueIndex === 0 && (
                <div
                  className="text-center font-weight-bold mt-1 mb-1"
                  style={{ width: "100%" }}
                >
                  OR
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export const LongEnglishQuestions = ({
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>
                  Q-{intToRoman(index + startIndex + startingQuestionNumber)}.{" "}
                </span>{" "}
                {ele.text}
              </div>
              <div className="col-2 text-right">{ele.marks}</div>
            </div>
          }

          {ele.ui_style == "rtl_row_2_bullet" && (
            <>
              <div className="row rtl-list text-right">
                {ele?.question_ids?.map((ques, queIndex) => (
                  <BulletSubQuestions
                    col="col-6"
                    number={`${queIndex + 1}.`}
                    text={parse(`${ques.question}`)}
                  />
                ))}
              </div>
            </>
          )}

          {ele.ui_style == "single_line_bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-12"
                  number={`${numberToAlphabet(queIndex + 1)}.`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "bullet" && (
            <div className="row mx-5">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col={is10 ? `col-4` : `col-12`}
                  number={
                    is10
                      ? `(${convertToRoman(queIndex + 1)})`
                      : `${convertToRoman(queIndex + 1)}.`
                  }
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "punjab_10_english_essay_writing_and_para" && (
            <Punjab10EssayWriting data={ele} />
          )}

          {ele.ui_style == "punjab_10_translation_with_ten_sentences" && (
            <Punjab10ParaTranslation data={ele} />
          )}

          {!ele.ui_style && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <div className="col-12">
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ minWidth: "35px", fontWeight: "bold" }}>
                      {queIndex == 0 && !ele.text
                        ? `Q${index + startIndex + startingQuestionNumber}.`
                        : ""}
                    </span>

                    <span
                      className="text-margin-none"
                      style={{ width: "100%" }}
                    >
                      {parse(`${ques.question}`)}
                    </span>

                    {queIndex == 0 && !ele.text && (
                      <div style={{ maxWidth: "100%" }} className="text-right">
                        {ele.marks}
                      </div>
                    )}
                  </div>

                  {queIndex + 1 < ele?.question_ids?.length && (
                    <div
                      className="text-center font-weight-bold mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      OR
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const LongPhysicsQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {
            <div className="row clearfix  mb-2">
              <div className="col-1 font-weight-bold">
                {subjectCheck === 7 ? (
                  <span>
                    Q.{intToRoman(index + startIndex + startingQuestionNumber)}{" "}
                  </span>
                ) : subjectCheck === 6 ? (
                  <span>Q-{index + startIndex + startingQuestionNumber} </span>
                ) : subjectCheck === 13 ? (
                  <span>Q.{index + startIndex + 2}. </span>
                ) : (
                  <span>{index + startIndex + startingQuestionNumber}. </span>
                )}{" "}
                {ele.text}
              </div>
              <div className="col-10">
                {ele.ui_style == "single_line_bullet" && (
                  <div className="row">
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={
                          subjectCheck === 13 || subjectCheck === 6
                            ? `(${numberToAlphabet(queIndex + 1)})`
                            : `${convertToRoman(queIndex + 1)}.`
                        }
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
                {ele.ui_style == "bullet" && (
                  <div className="row">
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={`${numberToAlphabet(queIndex + 1)}.`}
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="col-1 text-right">{ele.marks}</div>
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export const LongChemistryQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  if (!data || !data.paperLongQuestions) {
    return null;
  }

  return (
    <div style={{ width: "100%", fontFamily: "Arial, sans-serif" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>
          Note: Attempt any TWO questions. All questions carry equal marks.
        </div>
        <div style={{ fontWeight: "bold" }}>(2 x 10 = 20)</div>
      </div>

      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid black",
        }}
      >
        <tbody>
          {data.paperLongQuestions.map((question, index) => {
            const currentQuestionNumber =
              index + startIndex + startingQuestionNumber;

            return (
              <tr
                key={index}
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                <td
                  style={{
                    width: "50px",
                    padding: "10px",
                    border: "1px solid black",
                    verticalAlign: "top",
                  }}
                >
                  Q.{currentQuestionNumber}
                </td>
                <td style={{ padding: "10px" }}>
                  {question.question_ids &&
                    question.question_ids.map((subQ, subIndex) => {
                      const letter = String.fromCharCode(97 + subIndex);
                      let marks;

                      if (currentQuestionNumber === 3) {
                        marks = subIndex === 0 ? "(3+2)" : "(5)";
                      } else if (currentQuestionNumber === 4) {
                        marks = subIndex === 0 ? "(6)" : "(4)";
                      } else if (currentQuestionNumber === 5) {
                        marks = subIndex === 0 ? "(4)" : "(6)";
                      }

                      return (
                        <div
                          key={subIndex}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "8px 0",
                            borderBottom:
                              subIndex !== question.question_ids.length - 1
                                ? "1px solid #ddd"
                                : "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              alignItems: "flex-start",
                            }}
                          >
                            <span
                              style={{
                                marginRight: "10px",
                                fontWeight: "500",
                              }}
                            >
                              {letter}.
                            </span>
                            <div style={{ flex: 1 }}>
                              {parse(subQ.question)}
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: "20px",
                              minWidth: "45px",
                              textAlign: "right",
                            }}
                          >
                            {marks}
                          </div>
                        </div>
                      );
                    })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const LongBiologyQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  if (!data || !data.paperLongQuestions) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        fontFamily: "Arial, sans-serif",
        marginTop: "30px",
      }}
    >
      {/* Section Header */}
      {subjectCheck === 12 ? (
        <>
          <>
            <div
              style={{
                textAlign: "center",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",
                paddingBottom: "10px",
                marginBottom: "0",
              }}
            >
              <div style={{ fontWeight: "bold" }}>SECTION – C (Marks24)</div>
              <div>(3×8=24)</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 10px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <span style={{ fontWeight: "bold" }}>Note:</span> Attempt all
                  questions. Marks of each question are given along with each
                  question.
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingBottom: "10px",
            marginBottom: "0",
            ...(subjectCheck === 7 && {
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderTop: "1px solid black",
            }),
          }}
        >
          <div style={{ fontWeight: "bold" }}>SECTION – C (Marks 20)</div>
          {subjectCheck !== 13 && <div>(4×5=20)</div>}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ marginLeft: "5px" }}>
              <span style={{ fontWeight: "bold" }}>Note:</span>
              Attempt all questions. Marks of each question are given along with
              each question.
            </span>
            {subjectCheck === 13 && (
              <span style={{ marginLeft: "20px" }}>(4×5=20)</span>
            )}
          </div>
        </div>

      )}

      {/* Questions Table */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid black",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          {data.paperLongQuestions.map((question, index) => {
            // Changed the question numbering logic
            const currentQuestionNumber = index + 3; // Now starting from Q.3

            return (
              <tr key={index}>
                <td
                  style={{
                    width: "60px",
                    padding: "10px",
                    border: "1px solid black",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  Q.{currentQuestionNumber}
                </td>

                {/* Rest of the columns remain the same */}
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: "40%",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  {question.question_ids?.[0]?.question &&
                    parse(question.question_ids[0].question)}
                </td>

                <td
                  style={{
                    width: "40px",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  {question.marks || "5"}
                </td>

                <td
                  style={{
                    width: "50px",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    fontWeight: "bold",
                    verticalAlign: "top",
                    borderTop: "none",
                    fontSize: "18px",
                  }}
                >
                  OR
                </td>

                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: "40%",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  {question.question_ids?.[1]?.question &&
                    parse(question.question_ids[1].question)}
                </td>

                <td
                  style={{
                    width: "40px",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  {question.marks || "5"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const LongMathQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  if (!data || !data.paperLongQuestions) {
    return null;
  }

  // Function to format the question text with line breaks and remove <p> tags
  const formatQuestionText = (text) => {
    if (!text) return null;

    // Special handling for equations with measurements
    if (text.includes('=') && (text.includes('cm') || text.includes('m'))) {
      // Split the text at commas and 'and' to create natural line breaks
      const parts = text.split(/(?:,\s*|\s+and\s+)/);
      
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          width: '100%',
        }}>
          {parts.map((part, index) => (
            <div 
              key={index}
              style={{
                display: 'inline-block',
                width: '100%',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
                lineHeight: '1.5',
              }}
            >
              {parse(part.trim())}
            </div>
          ))}
        </div>
      );
    }

    // For regular math content
    if (text.includes('\\') || text.includes('frac') || text.includes('^')) {
      return (
        <div style={{
          display: 'inline-block',
          width: '100%',
          whiteSpace: 'normal',
          overflowWrap: 'break-word',
          fontSize: '0.95em',
          lineHeight: '1.5',
        }}>
          {parse(text)}
        </div>
      );
    }

    // For regular text content
    return (
      <div style={{
        width: '100%',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        lineHeight: '1.5',
      }}>
        {parse(text)}
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        fontFamily: "Arial, sans-serif",
        marginTop: "30px",
      }}
    >
      {/* Section Header */}
      {subjectCheck === 12 ? (
        <>
          <div
            style={{
              textAlign: "center",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderTop: "1px solid black",
              paddingBottom: "10px",
              marginBottom: "0",
            }}
          >
            <div style={{ fontWeight: "bold" }}>SECTION – C (Marks 24)</div>
            <div>(3×8=24)</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 10px",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "bold" }}>Note:</span> Attempt all
                questions. Marks of each question are given along with each
                question.
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            paddingBottom: "10px",
            marginBottom: "0",
            ...(subjectCheck === 7 && {
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderTop: "1px solid black",
            }),
          }}
        >
          <div style={{ fontWeight: "bold" }}>SECTION – C (Marks 20)</div>
          <div>(4×5=20)</div>
          <div>
            <span style={{ fontWeight: "bold" }}>Note:</span> Attempt all
            questions. Marks of each question are given along with each
            question.
          </div>
        </div>
      )}

      {/* Questions Table */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid black",
          tableLayout: "fixed",
        }}
      >
        <tbody>
          {data.paperLongQuestions.map((question, index) => {
            const currentQuestionNumber = index + 3;

            return (
              <tr key={index}>
                <td
                  style={{
                    width: "8%",
                    padding: "10px",
                    border: "1px solid black",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  Q.{currentQuestionNumber}
                </td>

                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: "37%",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  <div style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                  }}>
                    {question.question_ids?.[0]?.question && (
                      <div style={{
                        width: '100%',
                        minWidth: 0,
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                        maxWidth: '100%',
                        lineHeight: '1.5',
                      }}>
                        {formatQuestionText(question.question_ids[0].question)}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  style={{
                    width: "8%",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  {question.marks || "5"}
                </td>

                <td
                  style={{
                    width: "10%",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    fontWeight: "bold",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  OR
                </td>

                <td
                  style={{
                    padding: "10px",
                    border: "1px solid black",
                    width: "37%",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  <div style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                  }}>
                    {question.question_ids?.[1]?.question && (
                      <div style={{
                        width: '100%',
                        minWidth: 0,
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                        maxWidth: '100%',
                        lineHeight: '1.5',
                      }}>
                        {formatQuestionText(question.question_ids[1].question)}
                      </div>
                    )}
                  </div>
                </td>

                <td
                  style={{
                    width: "8%",
                    padding: "10px",
                    border: "1px solid black",
                    textAlign: "center",
                    verticalAlign: "top",
                    borderTop: "none",
                  }}
                >
                  {question.marks || "5"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Punjab10EssayWriting = ({ data }) => {
  const essayWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 446
  );
  const paragraphWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 446
  );
  return (
    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "35px" }} />
      <div className="row">
        {essayWriting.map((ques, queIndex) => (
          <BulletSubQuestions
            col="col-4"
            number={`(${convertToRoman(queIndex + 1)})`}
            text={parse(`${ques.question}`)}
          />
        ))}

        {paragraphWriting.length > 0 && (
          <>
            <div className="col-12 text-center font-weight-bold mt-1 mb-1">
              OR
            </div>

            <div
              className="col-12 font-weight-bold mb-1"
              style={{ marginLeft: "-10px" }}
            >
              Write a paragraph of 100-150 words on any ONE of the following
              topics:
            </div>

            {paragraphWriting.map((ques, index) => (
              <BulletSubQuestions
                col="col-4"
                number={`(${convertToRoman(index + 1)})`}
                text={parse(`${ques.question}`)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const Punjab10ParaTranslation = ({ data }) => {
  const paraTranslation = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 448
  );
  const tenSentences = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 448
  );
  return (
    <>
      <div className="row rtl-list text-right">
        {paraTranslation?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>

      {tenSentences.length > 0 && (
        <div
          className="text-center font-weight-bold mt-1 mb-1"
          style={{ width: "100%" }}
        >
          OR
        </div>
      )}

      <div className="row">
        {tenSentences?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>
    </>
  );
};

const BulletSubQuestions = ({ col, number, text }) => {
  return (
    <p
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    >
      <span style={{ minWidth: "35px", fontWeight: "500" }}>{number}</span>
      <span>{text}</span>
    </p>
  );
};


