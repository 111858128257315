import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// util
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";

const alphabets = ["a", "b", "c", "d", "e", "f", "g", "h"];

export const ComputerShortQuestions = ({ data, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-12">
          <span>Note: {data?.text}</span>
        </div>
      </div>

      {data?.paperShortQuestions?.map((ele, index1) => (
        <div key={index1} className="mb-3">
          {subject_id === 16 || subject_id === 18 ? (
            <>
              {ele?.sq_question_ids?.map((ques, index) => (
                <>
                  <div className="col-12" style={{ display: "flex", margin: 0 }}>
                    <div style={{ 
                      minWidth: "45px",  // Fixed width for question number container
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "flex-start"
                    }}>
                      {subject_id === 18 ? `Q.${index + startingQuestionNumber}` : `${index + startingQuestionNumber}.`}
                    </div>
                    <div style={{ flex: 1 }}>
                      {parse(`${ques.question}`)}
                    </div>
                  </div>
                  {ques.file && (
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-0.5rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <img
                        src={`${IMAGE_URL}/${ques.file}`}
                        alt="image"
                        style={{ maxWidth: "500px" }}
                      />
                    </div>
                  )}
                </>
              ))}
            </>
          ) : (
            <>
              {ele?.sq_question_ids?.map((ques, index) => (
                <>
                  <div className="col-12" style={{ display: "flex", margin: 0 }}>
                    <div style={{ 
                      minWidth: "45px",  // Fixed width for question number container
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "flex-start"
                    }}>
                      {subject_id === 18 && "Q."}
                      {index1 + startingQuestionNumber}.
                    </div>
                    <div style={{ flex: 1 }}>
                      {parse(`${ques.question}`)}
                    </div>
                  </div>
                  {ques.file && (
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-0.5rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <img
                        src={`${IMAGE_URL}/${ques.file}`}
                        alt="image"
                        style={{ maxWidth: "500px" }}
                      />
                    </div>
                  )}
                </>
              ))}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
export const ShortQuestions = ({ data, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      {data?.paperShortQuestions?.map((ele) => (
        <div className="mb-3">
          <div className={`row clearfix mb-2 font-weight-bold`}>
            <div className="col-12">
              <span>Note: {ele.text}</span>
            </div>
          </div>

          {ele?.sq_question_ids?.map((ques, index) => (
            <>
              <div className="col-12" style={{ display: "flex", margin: 0 }}>
                <div style={{ 
                  minWidth: "45px",  // Fixed width for question number container
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "flex-start"
                }}>
                  Q.{index + startingQuestionNumber}
                </div>
                <div style={{ flex: 1 }}>
                  {isMath ? (
                    <MathJax math={`${ques.question}`} />
                  ) : (
                    parse(`${ques.question}`)
                  )}
                </div>
              </div>
              {ques.file && (
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={`${IMAGE_URL}/${ques.file}`}
                    alt="image"
                    style={{ maxWidth: "500px" }}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

// export const ComputerLongQuestions = ({
//   data,
//   startIndex,
//   subject_id,
//   isMcqExist,
// }) => {
//   const isMath = ["5", "12"].includes(`${subject_id}`);
//   const startingQuestionNumber = isMcqExist ? 2 : 1;
//   return (
//     <div>
//       <div className="row clearfix font-weight-bold mb-2">
//         <div className="col-10">
//           <span>Note: {data.text} </span>
//         </div>
//       </div>
//       {data?.paperLongQuestions?.map((ele, index) => (
//         <div className="mb-3">
//           {ele?.question_ids?.map((ques, QueIndex) => (
//             <>
//               <div className="col-12" style={{ display: "flex", margin: 0 }}>
//                 <div style={{ 
//                   minWidth: "45px",  // Fixed width for question number container
//                   fontWeight: "bold",
//                   display: "flex",
//                   alignItems: "flex-start"
//                 }}>
//                   Q.{index + startIndex + startingQuestionNumber}.
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   {isMath ? (
//                     <MathJax math={`${ques.question}`} />
//                   ) : (
//                     parse(`${ques.question}`)
//                   )}
//                 </div>
//               </div>

//               {ques.file && (
//                 <div
//                   className="col-12"
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     marginTop: "-0.5rem",
//                     marginBottom: "1rem",
//                   }}
//                 >
//                   <img
//                     src={`${IMAGE_URL}/${ques.file}`}
//                     alt="image"
//                     style={{ maxWidth: "500px" }}
//                   />
//                 </div>
//               )}
//             </>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };
export const PakStudyLongQuestions = ({
  data,
  startIndex,
  subject_id,
  isMcqExist,
}) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <div className="row clearfix font-weight-bold mb-2">
        <div className="col-10">
          <span>Note: {data.text} </span>
        </div>
      </div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-3">
          {ele.ui_style == "" && (
            <>
              {ele?.question_ids?.map((ques, QueIndex) => (
                <>
                  <div className="col-12" style={{ display: "flex", margin: 0 }}>
                    <div style={{ 
                      minWidth: "45px",  // Fixed width for question number container
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "flex-start"
                    }}>
                      {subject_id === 18 ? "Q." : ""}{" "}
                      {index + startIndex + startingQuestionNumber}.
                    </div>
                    <div style={{ flex: 1 }}>
                      {parse(`${ques.question}`)}
                    </div>
                  </div>

                  {ques.file && (
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-0.5rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <img
                        src={`${IMAGE_URL}/${ques.file}`}
                        alt="image"
                        style={{ maxWidth: "500px" }}
                      />
                    </div>
                  )}
                </>
              ))}
            </>
          )}
          {ele.ui_style == "lq_bullets" && (
            <div className="row clearfix mb-2">
              <div style={{ marginLeft: "1rem" }} className="col-10">
                <div className="row">
                  {ele?.question_ids?.map((ques, queIndex) => (
                    <>
                      <div className="col-1"></div>
                      <BulletSubQuestions
                        col="col-11"
                        number={`(${convertToRoman(queIndex + 1)})`}
                        text={parse(`${ques.question}`)}
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export const LongQuestions = ({ data, startIndex, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-10">
              <span>Note: {ele.text} </span>
            </div>
          </div>
          {ele?.question_ids?.map((ques, QueIndex) => (
            <>
              <div className="col-12" style={{ display: "flex", margin: 0 }}>
                <div style={{ 
                  minWidth: "45px",  // Fixed width for question number container
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "flex-start"
                }}>
                  Q.{QueIndex + startIndex + startingQuestionNumber}
                </div>
                <div style={{ flex: 1 }}>
                  {isMath ? (
                    <MathJax math={`${ques.question}`} />
                  ) : (
                    parse(`${ques.question}`)
                  )}
                </div>
              </div>

              {ques.file && (
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={`${IMAGE_URL}/${ques.file}`}
                    alt="image"
                    style={{ maxWidth: isMath ? "300px" : "500px" }}
                  />
                </div>
              )}
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export const EnglishShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
}) => {
  const isEnglish = ["1", "11"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      {data?.paperShortQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div
            className={`row clearfix mb-2 ${isEnglish && "font-weight-bold"}`}
          >
            <div className="col-10">
              <span>Q.{index + startingQuestionNumber} </span>
              {ele.text}
            </div>
            <div className="col-2 text-right">{ele.marks}</div>
          </div>

          {ele.ui_style == "do_as_directed" &&
            (class_id == 2 ? (
              <DoAsDirected10 questions={ele?.sq_question_ids} />
            ) : (
              <DoAsDirected9 questions={ele?.sq_question_ids} />
            ))}

          {ele.ui_style != "do_as_directed" &&
            ele?.sq_question_ids?.map((ques, index) => {
              return (
                <>
                  {ele.ui_style == "list" && (
                    <div className="col-12" style={{ display: "flex", margin: 0 }}>
                      <div style={{ 
                        minWidth: "45px",  // Fixed width for question number container
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                        {`(${convertToRoman(index + 1)})`}
                      </div>
                      <div style={{ flex: 1 }}>
                        {parse(`${ques.question}`)}
                      </div>
                    </div>
                  )}

                  {ele.ui_style == "none" && (
                    <div className="col-12" style={{ display: "flex", margin: 0 }}>
                      <div style={{ 
                        minWidth: "45px",  // Fixed width for question number container
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "flex-start"
                      }}>
                        {`(${convertToRoman(index + 1)})`}
                      </div>
                      <div style={{ flex: 1 }}>
                        {parse(`${ques.question}`)}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      ))}
    </div>
  );
};

const DoAsDirected10 = ({ questions }) => {
  const articles = questions.filter((ele) => ele.chapter_id == 496);
  const directIndirect = questions.filter((ele) => ele.chapter_id == 492);
  const formation = questions.filter((ele) => ele.chapter_id == 493);
  const preposition = questions.filter((ele) => ele.chapter_id == 491);
  const punctuation = questions.filter((ele) => ele.chapter_id == 494);
  const suffix = questions.filter((ele) => ele.chapter_id == 488);
  const voice = questions.filter((ele) => ele.chapter_id == 490);
  const prefix = questions.filter((ele) => ele.chapter_id == 489);

  const UI = ({ count, array }) => {
    return (
      <div className="col-12 mb-2" style={{ display: "flex", margin: 0 }}>
        <div style={{ 
          minWidth: "45px",  // Fixed width for question number container
          fontWeight: "bold",
          display: "flex",
          alignItems: "flex-start"
        }}>
          {`(${convertToRoman(count)})`}
        </div>
        <div style={{ flex: 1 }}>
          {array?.map((ele, index) => (
            <BulletSubQuestions
              number={`${alphabets[index]})`}
              text={parse(`${ele.question}`)}
            />
          ))}
        </div>
      </div>
    );
  };

  const list = [
    articles,
    directIndirect,
    formation,
    preposition,
    punctuation,
    suffix,
    voice,
    prefix,
  ];

  return (
    <>
      {list
        .filter((ele) => ele.length > 0)
        .map((ele, index) => (
          <UI count={index + 1} array={ele} />
        ))}
    </>
  );
};
const DoAsDirected9 = ({ questions }) => {
  const articles = questions.filter((ele) => ele.chapter_id == 459);
  const synonym = questions.filter((ele) => ele.chapter_id == 487);
  const directIndirect = questions.filter((ele) => ele.chapter_id == 486);
  const suffix = questions.filter((ele) => ele.chapter_id == 482);
  const tense = questions.filter((ele) => ele.chapter_id == 485);
  const voice = questions.filter((ele) => ele.chapter_id == 484);
  const prefix = questions.filter((ele) => ele.chapter_id == 483);

  const UI = ({ count, array }) => {
    return (
      <div className="col-12 mb-2" style={{ display: "flex", margin: 0 }}>
        <div style={{ 
          minWidth: "45px",  // Fixed width for question number container
          fontWeight: "bold",
          display: "flex",
          alignItems: "flex-start"
        }}>
          {`(${convertToRoman(count)})`}
        </div>
        <div style={{ flex: 1 }}>
          {array?.map((ele, index) => (
            <BulletSubQuestions
              number={`${alphabets[index]})`}
              text={parse(`${ele.question}`)}
            />
          ))}
        </div>
      </div>
    );
  };

  const list = [
    articles,
    synonym,
    directIndirect,
    tense,
    suffix,
    voice,
    prefix,
  ];

  return (
    <>
      {list
        .filter((ele) => ele.length > 0)
        .map((ele, index) => (
          <UI count={index + 1} array={ele} />
        ))}
    </>
  );
};

export const LongEnglishQuestions = ({ data, startIndex, isMcqExist }) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {!!ele.text && (
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>Q.{index + startIndex + startingQuestionNumber} </span>{" "}
                {ele.text}
              </div>
              <div className="col-2 text-right">{ele.marks}</div>
            </div>
          )}

          {ele.ui_style == "sindh_10_english_essay_writing" && (
            <Sindh10EssayWriting data={ele} />
          )}

          {ele.ui_style == "rtl_row_2_bullet" && (
            <div className={"row rtl-list text-right"}>
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-6"
                  number={`(${convertToRoman(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "single_line_bullet" && (
            <div className={"row"}>
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-12"
                  number={`(${convertToRoman(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "bullet" && (
            <div className={"row"}>
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-3"
                  number={`(${convertToRoman(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {!ele.ui_style && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <div className="col-12">
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ minWidth: "35px", fontWeight: "bold" }}>
                      {queIndex == 0 && !ele.text
                        ? `Q${index + startIndex + startingQuestionNumber}.`
                        : ""}
                    </span>

                    <span
                      className="text-margin-none"
                      style={{ width: "100%" }}
                    >
                      {parse(`${ques.question}`)}
                    </span>

                    {queIndex == 0 && !ele.text && (
                      <div
                        style={{ maxWidth: "100%" }}
                        className="text-right font-weight-bold"
                      >
                        {ele.marks}
                      </div>
                    )}
                  </div>

                  {queIndex + 1 < ele?.question_ids?.length && (
                    <div
                      className="text-center font-weight-bold mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      OR
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Sindh10EssayWriting = ({ data }) => {
  const essayWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 476
  );
  const essayWritingPrompt = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 476
  );
  return (
    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "35px" }} />
      <div className="row">
        {essayWriting.map((ques, queIndex) => {
          return (
            <>
              <BulletSubQuestions
                col="col-12"
                number={`(${convertToRoman(queIndex + 1)})`}
                text={parse(`${ques.question}`)}
              />
            </>
          );
        })}

        {essayWritingPrompt.length > 0 && (
          <>
            <div className="col-12 text-center font-weight-bold mt-1 mb-1">
              OR
            </div>

            {essayWritingPrompt.map((ques) => {
              return (
                <span className="col-12">{parse(`${ques.question}`)}</span>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

const BulletSubQuestions = ({ col, number, text }) => {
  return (
    <div
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    >
      <span style={{ margin: "0 5px", fontWeight: "500" }}>{number}</span>
      <span>{text}</span>
    </div>
  );
};
