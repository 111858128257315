import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import { MCQSFormat } from "./MCQS";
import {
  LongEnglishQuestions,
  LongQuestions,
  EnglishShortQuestions,
  ShortQuestions,
  ComputerShortQuestions,
  ComputerLongQuestions,
  PakStudyLongQuestions,
} from "./Questions";
import PaperStudentEntrySindh from "./PaperStudentEntrySindh";

const SindhPaperFormat = ({ data, showMcqAnswer }) => {
  console.log(data, "data sindh");
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const isEnglish = ["1", "11"].includes(`${subjectCheck}`);
  const isMcqExist = !!(data?.paperMcqs.length > 0);

  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );

  return (
    <div style={{ color: "black", fontSize: "22px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />
            {/*  */}
            {subjectCheck === 16 ? (
              <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} />
            ) : (
              <>
                {subjectCheck === 18 ? (
                  <PaperStudentEntry data={data} />
                ) : (
                  <PaperStudentEntry data={data} />
                )}
              </>
            )}

            {subjectCheck === 18 ? (
              <>
                {isMcqExist &&
                  isShortQuestionsExist &&
                  isLongQuestionsExist && (
                    <GeneralInstructions subject_id={subjectCheck} />
                  )}
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6 ">
                    Max. Marks: {data?.paper_details[0].paper_marks}
                  </div>
                  <div className="col-6 text-right">
                    {/* Time: 3 Hours */}
                    {/* {data?.paper_details[0].objective_time} */}
                  </div>
                  <div className="col-12 text-center  text-decoration-cls">
                    SECTION “A” MULTIPLE CHOICE QUESTIONS (MCQs)
                  </div>
                  <div className="col-12 text-center text-decoration-cls">
                    Marks:
                    {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>
            ) : (
              <>
                {subjectCheck === 16 ? (
                  <>
                    <div className="row clearfix font-weight-bold mb-3">
                      <div className="col-6">
                        Time: {data?.paper_details[0].objective_time}
                      </div>
                      <div className="col-6 text-right">
                        Max. Marks: {data?.paper_details[0].objective_marks}
                      </div>
                    </div>
                    {isMcqExist &&
                      isShortQuestionsExist &&
                      isLongQuestionsExist && (
                        <GeneralInstructions subject_id={subjectCheck} />
                      )}
                    <div className="text-decoration-cls font-weight-bold col-12 text-center mb-2">
                      SECTION ‘A’ (COMPULSORY) MULTIPLE CHOICE QUESTIONS
                      (M.C.Qs)
                    </div>
                  </>
                ) : (
                  <>
                    {isMcqExist &&
                      isShortQuestionsExist &&
                      isLongQuestionsExist && (
                        <GeneralInstructions subject_id={subjectCheck} />
                      )}
                    <div className="row clearfix font-weight-bold mb-3">
                      <div className="col-12 text-center">SECTION “A”</div>
                      <div className="col-12 text-center mb-2">
                        MULTIPLE CHOICE QUESTIONS (MCQs)
                      </div>

                      <div className="col-6">
                        Time: {data?.paper_details[0].objective_time}
                      </div>
                      <div className="col-6 text-right">
                        Total Marks: {data?.paper_details[0].objective_marks}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}

            <MCQInstruction subjectCheck={subjectCheck} />

            <div className="font-weight-bold">
              <span>{subjectCheck === 18 ? "1. " : "Q.1 "}</span>
              {data?.paper_details[0].objective_note}
            </div>

            <MCQSFormat subjectCheck={subjectCheck} data={data} />
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          {subjectCheck === 18 ? (
            <PaperStudentEntry data={data} />
          ) : (
            <>
              {subjectCheck === 16 ? (
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6">
                    Time: {data?.paper_details[0].subjective_time}
                  </div>
                  <div className="col-6 text-right">
                    Total Marks: {data?.paper_details[0].subjective_marks}
                  </div>
                </div>
              ) : (
                <>
                  <PaperStudentEntry data={data} />
                  <div className="row clearfix font-weight-bold mb-3">
                    <div className="col-6">
                      Time: {data?.paper_details[0].subjective_time}
                    </div>
                    <div className="col-6 text-right">
                      Total Marks: {data?.paper_details[0].subjective_marks}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {!!isShortQuestionsExist && (
            <>
              {subjectCheck === 18 ? (
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-12 text-center">SECTION “B”</div>
                  <div className="col-2" />
                  <div className="col-8 text-center mb-2 text-decoration-cls">
                    (Short Answer Question)
                  </div>
                  <div className="col-2 text-right">
                    Marks: {data?.section[0]?.marks}
                  </div>
                </div>
              ) : (
                <>
                  {subjectCheck === 16 ? (
                    <div className="row clearfix mb-3">
                      <div className="col-12 font-weight-bold text-decoration-cls">
                        Important Instructions:
                      </div>
                      <div className="col-12 mb-2">
                        This paper consists of Short-Answer Questions (Section
                        ‘B’) and Descriptive-Answer Questions (Section ‘C’) will
                        be given after 30 minutes. Its total duration is 2 ½
                        hours only.
                      </div>
                      <div className="col-12 font-weight-bold text-center text-decoration-cls">
                        SECTION “B” (Short-Answers Questions)
                      </div>

                      <div className="col-12 font-weight-bold text-center">
                        ({data?.section[0]?.marks} marks)
                      </div>
                    </div>
                  ) : (
                    <div className="row clearfix font-weight-bold mb-3">
                      <div className="col-12 text-center">SECTION “B”</div>
                      <div className="col-2" />
                      <div className="col-8 text-center mb-2">
                        SHORT ANSWERS QUESTIONS
                      </div>
                      <div className="col-2 text-right">
                        ({data?.section[0]?.marks} marks)
                      </div>
                    </div>
                  )}
                </>
              )}

              {isEnglish ? (
                <EnglishShortQuestions
                  subject_id={subjectCheck}
                  class_id={data?.paper_details[0]?.class_id}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              ) : subjectCheck === 18 ? (
                <ComputerShortQuestions
                  subject_id={subjectCheck}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              ) : subjectCheck === 16 ? (
                <ComputerShortQuestions
                  subject_id={subjectCheck}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              ) : (
                <ShortQuestions
                  subject_id={subjectCheck}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              )}
            </>
          )}

          {!!isLongQuestionsExist && (
            <>
              <div className="row clearfix font-weight-bold mb-3">
                {subjectCheck === 16 ? (
                  <></>
                ) : (
                  <div className="col-12 text-center">SECTION “C”</div>
                )}

                <div className="col-2" />
                {subjectCheck === 18 ? (
                  <>
                    <div className="col-8 text-center mb-2">
                      (DESCRIPTIVE – ANSWER QUESTIONS)
                    </div>
                    <div className="col-2 text-right">
                      Marks: {data?.section[0]?.marks}
                    </div>
                  </>
                ) : (
                  <>
                    {subjectCheck === 16 ? (
                      <>
                        <div className="col-12 text-decoration-cls text-center">
                          Section “C” (Detailed-Answer Questions)
                        </div>
                        <div className="col-12 text-center mb-2">
                          ({data?.section[0]?.marks} marks)
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-8 text-center mb-2">
                          DESCRIPTIVE-ANSWER QUESTIONS
                        </div>
                        <div className="col-2 text-right">
                          ({data?.section[0]?.marks} marks)
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              {isEnglish ? (
                <LongEnglishQuestions
                  data={data?.section[1]}
                  startIndex={
                    data?.section[0]?.paperShortQuestions?.length || 0
                  }
                  isMcqExist={isMcqExist}
                />
              ) : (
                <>
                  {subjectCheck === 18 || subjectCheck === 16 ? (
                    <PakStudyLongQuestions
                    subject_id={subjectCheck}
                    data={data?.section[1]}
                    startIndex={
                      data?.section[0]?.paperShortQuestions[0]
                        ?.sq_question_ids?.length || 0
                    }
                    isMcqExist={isMcqExist}
                  />
                  )  : (
                    <LongQuestions
                      subject_id={subjectCheck}
                      data={data?.section[1]}
                      startIndex={
                        data?.section[0]?.paperShortQuestions[0]
                          ?.sq_question_ids?.length || 0
                      }
                      isMcqExist={isMcqExist}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            <PaperStudentEntry data={data} />

            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-12 text-center mb-2">
                MULTIPLE CHOICE QUESTIONS (MCQs)
              </div>

              <div className="col-6">
                Time: {data?.paper_details[0].objective_time}
              </div>
              <div className="col-6 text-right">
                Total Marks: {data?.paper_details[0].objective_marks}
              </div>
            </div>

            <MCQInstruction />

            <div className="font-weight-bold">
              <span>Q1. </span>
              {data?.paper_details[0].objective_note}
            </div>

            <MCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
          </div>
        </>
      )}
    </div>
  );
};

export default SindhPaperFormat;

const GeneralInstructions = ({ subject_id }) => {
  const isChemistry = ["6"].includes(`${subject_id}`);
  const isBio = ["7"].includes(`${subject_id}`);
  const isEnglish = ["1", "11"].includes(`${subject_id}`);
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isPhysic = ["13"].includes(`${subject_id}`);
  const isComputer = ["18"].includes(`${subject_id}`);
  const isPakStudy = ["16"].includes(`${subject_id}`);

  const EnglishPaperGeneralInstruction = () => {
    return (
      <div className="row clearfix mb-3">
        <div className="col-12 font-weight-bold mb-2 text-decoration-cls">
          General Instructions:
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘A’:</span> It consists of
          20 Multiple Choice Questions (MCQs) and all of them are to be
          answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘B’:</span> It consists of
          04 Short- Answer Questions and all of them are to be answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘C’:</span> It consists of
          04 Descriptive Answer-Questions and all of them are to be answered.
        </div>
      </div>
    );
  };

  const PhysicsPaperGeneralInstruction = () => {
    return (
      <div className="row clearfix mb-3">
        <div className="col-12 font-weight-bold mb-2 text-decoration-cls">
          General Instructions:
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘A’:</span> It consists of
          12 Multiple Choice Questions (MCQs) and all of them are to be
          answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘B’:</span> It consists of
          12 questions out of which 08 are to be answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘C’:</span> It comprises 05
          Questions out of which 04 questions are to be answered.
        </div>
      </div>
    );
  };

  const MathPaperGeneralInstruction = () => {
    return (
      <div className="row clearfix mb-3">
        <div className="col-12 font-weight-bold mb-2 text-decoration-cls">
          General Instructions:
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘A’:</span> It consists of
          15 Multiple Choice Questions (MCQs) and all of them are to be
          answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘B’:</span> It consists of
          10 questions out of which 06 are to be answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘C’:</span> It comprises 05
          Questions out of which 03 questions are to be answered.
        </div>
      </div>
    );
  };
  const PakStudyPaperGeneralInstruction = () => {
    return (
      <div className="row clearfix mb-3">
        <div className="col-12 font-weight-bold mb-2 text-decoration-cls">
          General Instructions:
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘A’:</span> I It consists
          of 15 Multiple Choice Questions (MCQs) and all of them are to be
          answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘B’:</span> It consists of
          05 Short- Answer Questions and all of them are to be answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘C’:</span> It consists of
          04 Descriptive Answer-Questions and all of them are to be answered.
        </div>
      </div>
    );
  };

  const DefaultPaperGeneralInstruction = () => {
    return (
      <div className="row clearfix mb-3">
        <div className="col-12 font-weight-bold mb-2 text-decoration-cls">
          General Instructions:
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘A’:</span> It consists of
          12 Multiple choice questions (MCQs) and all of them are to be
          answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘B’:</span> It consists of
          12 questions out of which 08 are to be answered.
        </div>
        <div className="col-12">
          <span className="font-weight-bold">Section ‘C’:</span> It comprises 05
          Questions out of which 04 questions are to be answered.
        </div>
      </div>
    );
  };

  if (isEnglish) return <EnglishPaperGeneralInstruction />;
  if (isPhysic) return <PhysicsPaperGeneralInstruction />;
  if (isMath) return <MathPaperGeneralInstruction />;
  if (isChemistry) return <></>;
  if (isBio) return <></>;
  if (isComputer) return <></>;
  if (isPakStudy) return <PakStudyPaperGeneralInstruction />;
};

const MCQInstruction = ({ subjectCheck }) => {
  return (
    <div className="row clearfix mb-3">
      <div
        className={`${
          !subjectCheck === 16 && "font-weight-bold text-decoration-cls"
        }col-1 mx-3 mb-2`}
      >
        Note:
      </div>
      <div className="col-11" style={{ marginLeft: "-40px" }}>
        {subjectCheck === 18 ? (
          <ol type="i">
            <li>Attempt all questions of this section.</li>
            <li>
              Do not copy down the questions. Write only the answer against the
              proper number of the question and its parts according to the
              question paper.
            </li>
            <li>Each question carries ONE mark.</li>
          </ol>
        ) : (
          <>
            {subjectCheck === 16 ? (
              <ol type="i">
                <li>Attempt all questions of this section.</li>
                <li>
                  Do not copy down the questions. Write only the answer against
                  the proper number of the question and its parts according to
                  the question paper.
                </li>
                <li>Each question carries ONE mark.</li>
                <li>
                  Write the code of your question paper in bold letters in the
                  beginning of the answer script.
                </li>
              </ol>
            ) : (
              <ul>
                <li>Attempt all questions of this section.</li>
                <li>
                  Do not copy down the questions. Write only the answer against
                  the proper number of the question and its parts according to
                  the question paper.
                </li>
                <li>Each question carries ONE mark.</li>
                <li>
                  Write the code of your question paper in{" "}
                  <span className="font-weight-bold text-decoration-cls">
                    bold letters
                  </span>{" "}
                  in the beginning of the answer script.
                </li>
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
};
