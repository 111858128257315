import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import PaperDetail from "./PaperDetail";
import MCQS from "./MCQS";
import SectionDetail from "./SectionDetail";
import { BackNextBtn } from "./CustomComponent";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";
import moment from "moment";

// api's hook
import { useCreatePunjabPaper } from "hooks/api/paper";
import { useGetChapters } from "hooks/api/subject";
import { getPaperData } from "Data/paperData";

const CreatePaper = () => {
  const { id } = useParams();

  const [stepper, setStepper] = useState(4);
  const [selectedMcqs, setSelectedMcqs] = useState([]);
  const [selectedMcqsChapters, setSetSelectedMcqsChapters] = useState([]);
  const [section1, setSection1] = useState({});
  const [section2, setSection2] = useState({});
  const [randomSelection, setRandomSelection] = useState({
    all: false,
    mcqs: false,
    shortQuestions: false,
    longQuestions: false,
  });
console.log("randomSelection",randomSelection)
  const createPaper = useCreatePunjabPaper();
  const { data: chapters } = useGetChapters(); 

  const { handleSubmit, control, formState, setValue, watch } = useForm();
  async function onSubmit(data) {
    try {
      console.log("MCQs being sent to backend:", {
        mcqs_Ids: selectedMcqs.map((ele) => ele.id),
        randomMcqs: randomSelection?.mcqs ? 1 : 0,
        mcqs_Chapters_ids: randomSelection?.mcqs ? selectedMcqsChapters : [],
        noOfMcqs: data.noOfMcqs
      });

      const body = {
        sessionId: Number(data.sessionId),
        paper_board_type_id: Number(data.board_id),
        classId: Number(data.class_id),
        subjectId: Number(data.subject_id),
        paper_type_id: Number(data.paper_type_id),
        paper_time: data.paper_time,
        paper_marks: data.paper_marks,
        paperDate: moment(new Date(data.paperDate), "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ),

        paper_title: data.paper_title,
        objective_note: data.objective_note,
        objective_marks: data.objective_marks,
        objective_time: data.objective_time,

        mcqs_Ids: selectedMcqs.map((ele) => ele.id),
        randomMcqs: randomSelection?.mcqs ? 1 : 0,
        mcqs_Chapters_ids: randomSelection?.mcqs ? selectedMcqsChapters : [],
        noOfMcqs: data.noOfMcqs,

        subjective_time: data.subjective_time,
        subjective_marks: data.subjective_marks,

        section: [
          {
            section_title: ["1", "11"].includes(`${data.subject_id}`)
              ? "SECTION-I"
              : "PART-1",
            text: ["10", "11"].includes(`${data.paper_type_id}`)
              ? ""
              : section1?.note || "",
            marks: section1?.marks || "",
            question: section1?.questions?.map((ele) => ({
              name: "Q.",
              text: ele?.note || "",
              marks: ele?.marks || "",
              ui_style: ele?.ui_style || "", 
              question_Ids: randomSelection?.shortQuestions
                ? []
                : ele?.parts?.map(({ id }) => id),
              sq_question_chapters_ids: randomSelection?.shortQuestions
                ? ele?.chapterIds
                : [],
              randomSQuestions: randomSelection?.shortQuestions ? 1 : 0,
              noofSQuestion: ele?.questions,
            })),
          },
          {
            section_title: ["1", "11"].includes(`${data.subject_id}`)
              ? "SECTION-II"
              : "PART-2",
            text: section2?.note || "",
            marks: section2?.marks || "",
            question: section2?.questions?.map((ele) => ({
              name: "Q.",
              text: ele?.note || "",
              marks: ele?.marks || "",
              ui_style: ele?.ui_style || "", 
              question_Ids: !!randomSelection?.longQuestions
                ? []
                : ele?.parts?.map(({ id }) => id) || [],
              question_chapters_ids: !!randomSelection?.longQuestions
                ? ele?.chapterIds || []
                : [],
              randomQuestions: !!randomSelection?.longQuestions ? 1 : 0,
              noofQuestion: ele?.questions,
            })),
          },
        ],
      };

      await createPaper.mutateAsync(body);
    } catch (err) {}
  }

  const board_id = watch("board_id");
  const class_id = watch("class_id");
  const subject_id = watch("subject_id");
  const paper_type_id = watch("paper_type_id");
  const paper_time = watch("paper_time");
  const paper_marks = watch("paper_marks");
  const paperDate = watch("paperDate");

  useEffect(() => {
    if (!!board_id && !!subject_id && !!class_id) {
      const data = getPaperData({ board_id, subject_id, class_id });
      console.log("data data data:", data)
      Object.entries(data.instruction).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
      setSection1(data.short_question);
      setSection2(data.long_question);
    }
  }, [board_id, subject_id, class_id]);

  const chaptersOptions = useMemo(() => {
    let temp = [];
    if (board_id && class_id && subject_id) {
      for (let i = 0; i < chapters?.data?.length; i++) {
        if (
          chapters?.data[i].board_id == board_id &&
          chapters?.data[i].class_id == class_id &&
          chapters?.data[i].subject_id == subject_id
        ) {
          temp.push({
            label: chapters?.data[i].name,
            value: chapters?.data[i].id,
          });
        }
      }
    }
    return temp;
  }, [chapters, board_id, class_id, subject_id]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Generate Paper"
        Breadcrumb={[
          { name: "Papers", navigate: "/papers" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      {/* {stepper === 1 ? ( */}
      <>
        <PaperDetail
          formState={formState}
          control={control}
          watch={watch}
          randomSelection={randomSelection}
          setRandomSelection={setRandomSelection}
        />
      </>
      {/* ) : stepper === 2 ? ( */}
      <>
        <MCQS
          formState={formState}
          control={control}
          board_id={board_id}
          class_id={class_id}
          subject_id={subject_id}
          selectedMcqs={selectedMcqs}
          setSelectedMcqs={setSelectedMcqs}
          randomSelection={randomSelection}
          setRandomSelection={setRandomSelection}
          chaptersOptions={chaptersOptions}
          selectedMcqsChapters={selectedMcqsChapters}
          setSetSelectedMcqsChapters={setSetSelectedMcqsChapters}
        />
      </>
      <>
        <SectionDetail
          title="Part-I"
          board_id={board_id}
          class_id={class_id}
          subject_id={subject_id}
          sectionState={section1}
          setSectionState={setSection1}
          chaptersOptions={chaptersOptions}
          randomSelection={!!randomSelection?.shortQuestions}
          setRandomSelection={(e) =>
            setRandomSelection({
              ...randomSelection,
              shortQuestions: e.target.checked,
            })
          }
        />
      </>
      <>
        <SectionDetail
          title="Part-II"
          longQuestionStart={section1?.questions?.length}
          board_id={board_id}
          class_id={class_id}
          subject_id={subject_id}
          sectionState={section2}
          setSectionState={setSection2}
          chaptersOptions={chaptersOptions}
          randomSelection={!!randomSelection?.longQuestions}
          setRandomSelection={(e) =>
            setRandomSelection({
              ...randomSelection,
              longQuestions: e.target.checked,
            })
          }
        />
        <BackNextBtn
          stepper={stepper}
          setStepper={setStepper}
          onSave={handleSubmit(onSubmit)}
          saveBtnDisabled={formState.isSubmitting}
          nextBtnDisabled={section1?.questions?.length > 0 ? false : true}
        />
      </>
      {/* ) : null} */}

      <div className="p-4"></div>
    </div>
  );
};

export default CreatePaper;
